import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import Page, { Grid, GridColumn } from '@atlaskit/page'
import { State, User } from '../../types'

import { Main } from '@atlaskit/page-layout'

const Wrapper = styled.div`
  padding: 8px 40px;
`

const WelcomeGreeting = styled.div`
  margin: 32px 0 16px 0;
`

const ExploreLaavaHeading = styled.div`
  margin: 16px 0;
`

interface Props {
  user?: User
}

const mapStateToProps = (state: State) => {
  const user = state.account.login.user
  return {
    user,
  }
}

const Welcome: React.FC<Props> = ({ user }) => {
  return (
    <Main>
      <Wrapper>
        <Page>
          <Grid>
            <GridColumn medium={12}>
              <WelcomeGreeting>
                <h1>Hi {user?.first_name}!</h1>
                <p>Welcome to Laava Manage, get started by ordering some Smart Fingerprints.</p>
              </WelcomeGreeting>
            </GridColumn>
          </Grid>

          <Grid>
            <GridColumn medium={12}>
              <ExploreLaavaHeading>
                <h1>Explore Laava Manage</h1>
              </ExploreLaavaHeading>
            </GridColumn>
            <GridColumn medium={4}>
              <h4>You're ready to begin ordering!</h4>
              <p>To begin ordering Smart Fingerprints, you'll start by creating a project.</p>
              <a href="/projects">Get Started</a>
            </GridColumn>
            <GridColumn medium={4}>
              <h4>Smart Fingerprint Printing Guidelines</h4>
              <p>
                It's important to know how to print and implement your Smart Fingerprints. We've created guidelines to
                show you how.
              </p>
              <a href="https://hubs.ly/H0w2XSn0" target="_blank" rel="noopener noreferrer">
                View Guidelines
              </a>
            </GridColumn>
            <GridColumn medium={4}>
              <h4>Laava Developer Portal</h4>
              <p>
                We've published and released the first version of our API. The Laava REST API allows you to integrate
                and connect your Smart Fingerprints/QR/Logos with your other business systems.
              </p>
              <a href="https://developers.laava.id" target="_blank" rel="noopener noreferrer">
                Learn More
              </a>
            </GridColumn>
          </Grid>
        </Page>
      </Wrapper>
    </Main>
  )
}

export default connect(mapStateToProps, null)(Welcome)
