import React, { useEffect, useState, Fragment } from 'react'
import { connect } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import styled from 'styled-components'

import { Container, Col, Row } from 'react-grid-system'

import Button, { ButtonGroup } from '@atlaskit/button'
import PageHeader from '@atlaskit/page-header'
import TextField from '@atlaskit/textfield'
import Select from '@atlaskit/select'
import Form, { Field, HelperMessage } from '@atlaskit/form'
import InlineMessage from '@atlaskit/inline-message'
import Tooltip from '@atlaskit/tooltip'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

import ResultView, { getDefaultContentBlockForType, BlockType } from '@laava/result-view'

import { OrangeButton } from '../../components/styled/Button'
import ColorPicker from '../../components/react/color-picker'
import ContentJsonEditor from '../../components/react/content-json-editor'
import AssetSelector from '../../components/react/modals/asset-selector'
import {
  ResultViewEditorWrapper,
  SectionsWrapper,
  SectionContentsWrapper,
  SectionWrapper,
  BlockContentWrapper,
  SectionAndBlockPalette,
  EditPanel,
} from '../../components/react/result-view-editor'

import { State, Project, View, Asset } from '../../types'
import { fetchProjectsIfNeeded } from '../../state/projects/actions'
import {
  fetchAssetsIfNeeded,
  fetchViewsIfNeeded,
  setDraftView,
  setDraftViewIfNeeded,
  updateView,
} from '../../state/content/actions'
import { OptionsPropType } from '@atlaskit/radio/dist/types/types'
import { RadioGroup } from '@atlaskit/radio'
import { Restricted } from '../../providers/permission'

const productImageScaleModes: OptionsPropType = [
  { label: 'Cover', value: 'cover' },
  { label: 'Contain', value: 'contain' },
]
interface ProjectEditViewProps {
  view: any
  draftView: any
  project: Project
  assets?: any
  dispatch?: any
  history: any
}

export const FieldRow = styled.div`
  display: flex;
  align-items: flex-end;
`
export const ColorsRow = styled.div`
  display: flex;
  text-align: center;
`

const mapStateToProps = (state: State, ownProps: any) => {
  const project = state.projects.items.filter((project: Project) => project.id === ownProps.match.params.projectId)[0]

  const view = state.content.views.items?.filter((view: View) => view.id === ownProps.match.params.viewId)[0]
  const draftView = { ...state.content.views.draft }
  const checkedDraftView = checkAndUpdateView(draftView)

  return {
    project,
    draftView: checkedDraftView,
    assets: state.content.assets.items,
    view,
  }
}

const checkAndUpdateView = (view: any) => {
  if (!view.content) return view

  const updatedView = Object.assign({}, view)
  const { content } = updatedView
  const sections = content.sections || content.blocks

  // look for missing section and content IDs
  const sectionsWithIds = sections.map((section: any) => {
    return { ...section, id: section.id || uuidv4() }
  })

  // get rid of 'blocks' and make it 'sections'
  const updatedContent = {}
  delete Object.assign(updatedContent, content)['blocks']
  const contentWithCheckedIds = Object.assign({}, updatedContent, { sections: sectionsWithIds })

  // check content blocks for missing ids
  sections.forEach((section: any) => {
    if (!section.id) {
      section.id = uuidv4()
    }

    const sectionContent = section.content || section.data.content

    sectionContent.forEach((block: any, blockIndex: number) => {
      if (!block.id) {
        block.id = uuidv4()
      }

      if (block.type === 'recipe' && !block.data) {
        // Deprecated
        // Updates recipe blocks from the old schema to the new
        block = { id: block.id, type: block.type, data: { ...block } }

        // Remove redundant data in destructured object
        delete block.data.type
        delete block.data.id

        // Ingredient & method list items were an array
        // They need to be updated into the 'list' format for draggable & backwards compatibility
        block.data.ingredients = block.data.ingredients?.map((ingredientList: any) => {
          return {
            ...ingredientList,
            items: ingredientList.items?.map((item: string) => {
              return { ingredientName: item }
            }),
          }
        })
        block.data.method = block.data.method?.map((methodList: any) => {
          return {
            ...methodList,
            steps: methodList.steps?.map((step: string) => {
              return { step }
            }),
          }
        })
        sectionContent[blockIndex] = block
      }
    })
  })

  // ensure there is a button style present in the view

  if (!updatedView.content.styles.button) {
    updatedView.content.styles.button = {
      font: 'Inter',
      size: '18px',
      weight: 'bold',
      lineHeight: 1.2,
    }
  }

  const viewToReturn = Object.assign({}, updatedView, { content: contentWithCheckedIds })

  return viewToReturn
}

const ProjectEditView: React.FC<ProjectEditViewProps> = ({ draftView, project, assets, view, dispatch, history }) => {
  const [openAssetSelectorId, setOpenAssetSelectorId] = useState('')
  const [iconImageOption, setIconImageOption] = useState('default')

  const [isSavingEnabled, setIsSavingEnabled] = useState(true)
  const [tabIndex, setTabIndex] = useState(0)

  const [editingContentBlock, setEditingContentBlock] = useState(0)
  const [editingSection, setEditingSection] = useState(0)

  // form state items

  const [displayState, setDisplayState] = useState('')
  const [displayDescription, setDisplayDescription] = useState('')
  const [displayDetails, setDisplayDetails] = useState('')
  const [productImageUrl, setProductImageUrl] = useState('')
  const [logoImageUrl, setLogoImageUrl] = useState('')

  useEffect(() => {
    dispatch(fetchProjectsIfNeeded())
    dispatch(fetchAssetsIfNeeded(project?.id))
    dispatch(fetchViewsIfNeeded(project?.id))
    dispatch(setDraftViewIfNeeded(view))
  })

  useEffect(() => {
    setDisplayState(draftView.display_state || '');
    setDisplayDescription(draftView.display_description || '');
    setDisplayDetails(draftView.display_details || '');
    setProductImageUrl(draftView.product_image_url || '');
    setLogoImageUrl(draftView.logo_image_url || '');
  }, [draftView]);  // Update state whenever draftView changes

  const onEditViewModalFormSubmit = () => {
    dispatch(updateView({ project_id: project.id, ...draftView }))
    history.push(`/projects/${project.id}/result-views/`)
  }

  useEffect(() => {
    view?.display_state && setDisplayState(view.display_state)
    view?.display_description && setDisplayDescription(view.display_description)
    view?.display_details && setDisplayDetails(view.display_details)
    view?.product_image_url && setProductImageUrl(view.product_image_url)
    view?.logo_image_url && setLogoImageUrl(view.logo_image_url)

    if (view?.icon_image_asset?.id) {
      setIconImageOption('custom')
    }
  }, [view])

  const submitButtonAction = (fields: { [key: string]: string }) => {
    for (const [key, value] of Object.entries(fields)) {
      handleProductInfoFormFieldChange({ target: { name: key, value } })
    }

    onEditViewModalFormSubmit()
  }

  const handleProductInfoFormFieldChange = (event: { target: { name: string; value: string } } | any) => {
    const fieldName = event.target.name

    let updatedDraftView = draftView

    switch (fieldName) {
      case 'display_state':
        updatedDraftView['display_state'] = event.target.value
        setDisplayState(event.target.value)
        break
      case 'display_description':
        updatedDraftView['display_description'] = event.target.value
        setDisplayDescription(event.target.value)
        break
      case 'display_details':
        updatedDraftView['display_details'] = event.target.value
        setDisplayDetails(event.target.value)
        break
      case 'product_image_asset_id':
        updatedDraftView['product_image_asset_id'] = event.target.value
        updatedDraftView['product_image_asset'] =
          assets.filter((asset: any) => asset.id === event.target.value)[0] || null
        updatedDraftView['product_image_url'] = null
        setProductImageUrl('')

        break
      case 'product_image_url':
        updatedDraftView['product_image_url'] = event.target.value
        setProductImageUrl(event.target.value)
        break
      case 'logo_image_asset_id':
        updatedDraftView['logo_image_asset_id'] = event.target.value
        updatedDraftView['logo_image_asset'] = assets.filter((asset: Asset) => asset.id === event.target.value)[0]
        updatedDraftView['logo_image_url'] = null
        setLogoImageUrl('')
        break
      case 'logo_image_url':
        updatedDraftView['logo_image_url'] = event.target.value
        setLogoImageUrl(event.target.value)
        break
      case 'icon_image_asset_id':
        updatedDraftView['icon_image_asset_id'] = event.target.value
        updatedDraftView['icon_image_asset'] = assets.filter((asset: Asset) => asset.id === event.target.value)[0]
        updatedDraftView['icon_image_url'] = null
        break
      case 'icon_image_url':
        updatedDraftView['icon_image_url'] = event.target.value
        break
      case 'content':
        updatedDraftView['content'] = event.target.value
        break
      case 'style.arrow_colour':
        updatedDraftView['style'].arrow_colour = event.target.value
        break
      case 'style.details_colour':
        updatedDraftView['style'].details_colour = event.target.value
        break
      case 'style.state_colour':
        updatedDraftView['style'].state_colour = event.target.value
        break
      case 'style.description_colour':
        updatedDraftView['style'].description_colour = event.target.value
        break
      case 'style.background_colour':
        updatedDraftView['style'].background_colour = event.target.value
        break
      case 'style.product_image_background_colour':
        updatedDraftView['style'].product_image_background_colour = event.target.value
        break
      case 'style.icon_background_colour':
        updatedDraftView['style'].icon_background_colour = event.target.value
        break
      case 'style.product_image_scale_mode':
        updatedDraftView['style'].product_image_scale_mode = event.target.value
        break
      case 'html_content_url':
        updatedDraftView['html_content_url'] = event.target.value
        break
      case 'view':
        updatedDraftView = event.target.value
    }

    dispatch(setDraftView(updatedDraftView))
  }

  const cancelButtonAction = () => {
    history.push(`/projects/${project.id}/result-views/`)
  }

  const actions = (
    <ButtonGroup>
      <Restricted to="collection-view:create" type="projects">
        <OrangeButton appearance="primary" type="submit" isDisabled={!isSavingEnabled}>
          Save
        </OrangeButton>
      </Restricted>
      <Button appearance="subtle" onClick={cancelButtonAction}>
        Cancel
      </Button>
    </ButtonGroup>
  )

  // Keeps onSubmit logic for our fields while disabling enter key submitting the form
  const onKeyPress = (event: any) => {
    if (event.target.type !== 'textarea' && event.which === 13 /* Enter */) {
      event.preventDefault()
    }
  }

  interface ResultViewContentWrappers {
    SectionsWrapper: any
    SectionContentsWrapper: any
    SectionWrapper: any
    sectionOverlayActions: any
    BlockContentWrapper: any
    blockContentOverlayActions: any
  }

  const onSectionEditButtonClicked = (id: number) => {
    setTabIndex(1)
    setEditingSection(id)
    setEditingContentBlock(0)
  }

  const onSectionCloneButtonClicked = (id: string) => {
    if (draftView.content) {
      const contentSections = draftView.content.sections
      let updatedContentSections = contentSections

      for (var i = 0; i < contentSections.length; i += 1) {
        if (contentSections[i].id === id) {
          const sectionToClone = contentSections[i]
          const sectionToCloneContent = sectionToClone.content || sectionToClone.data.content
          const sectionToCloneData = sectionToClone.data || sectionToClone

          const newSection = Object.assign({}, sectionToClone, { id: uuidv4() })
          const newSectionContent = sectionToCloneContent.map((contentItem: any) => {
            const updatedContentItem = Object.assign({}, contentItem, { id: uuidv4() })
            return (contentItem = updatedContentItem)
          })

          let newSectionData: any = {}
          // Unflatten deprecated section data into section.data
          for (const [key, value] of Object.entries(sectionToCloneData)) {
            if (key !== 'id' && key !== 'type') {
              delete newSection[key]
              newSectionData[key] = value
            }
          }

          const newSectionWithUpdatedContentIds = Object.assign({}, newSection, {
            data: { ...newSectionData, content: newSectionContent },
          })

          updatedContentSections.splice(i + 1, 0, newSectionWithUpdatedContentIds)
        }
      }

      onViewChange(updatedContentSections)
    }
  }

  const onSectionDeleteButtonClicked = (id: string) => {
    if (draftView.content) {
      const contentSections = draftView.content.sections
      let updatedContentSections = contentSections

      for (var i = 0; i < contentSections.length; i += 1) {
        if (contentSections[i].id === id) {
          if (window.confirm('Are you sure you want to delete this element?')) {
            setTabIndex(0)
            setEditingSection(0)
            setEditingContentBlock(0)
            updatedContentSections.splice(i, 1)
          }
        }
      }

      onViewChange(updatedContentSections)
    }
  }

  const onBlockContentEditButtonClicked = (id: number) => {
    if (draftView.content) {
      setTabIndex(1)
      setEditingContentBlock(id)
      setEditingSection(0)
    }
  }

  const onBlockContentCloneButtonClicked = (id: string) => {
    if (draftView.content) {
      const contentSections = draftView.content.sections
      let updatedContentSections = contentSections

      for (var i = 0; i < contentSections.length; i += 1) {
        const sectionContent = contentSections[i].data?.content || contentSections[i].content
        const updatedContentSectionContent =
          updatedContentSections[i].data?.content || updatedContentSections[i].content

        sectionContent.forEach((contentBlock: any, contentBlockIndex: number) => {
          if (contentBlock.id === id) {
            const newContentBlock = Object.assign({}, contentBlock, { id: uuidv4() })

            updatedContentSectionContent.splice(contentBlockIndex + 1, 0, newContentBlock)
          }
        })
      }

      onViewChange(updatedContentSections)
    }
  }

  const onBlockContentDeleteButtonClicked = (id: string) => {
    if (draftView.content) {
      const contentSections = draftView.content.sections
      let updatedContentSections = contentSections

      for (var i = 0; i < contentSections.length; i += 1) {
        const contentSectionsContent = contentSections[i].data?.content || contentSections[i].content
        const updatedContentSectionContent =
          updatedContentSections[i].data?.content || updatedContentSections[i].content

        contentSectionsContent.forEach((contentBlock: any, contentBlockIndex: number) => {
          if (contentBlock.id === id) {
            if (window.confirm('Are you sure you want to delete this element?')) {
              setTabIndex(0)
              setEditingSection(0)
              setEditingContentBlock(0)
              updatedContentSectionContent.splice(contentBlockIndex, 1)
            }
          }
        })
      }

      onViewChange(updatedContentSections)
    }
  }

  const updateContentBlock = (id: string, updatedContentBlock: any) => {
    if (draftView.content) {
      const viewContent = draftView.data?.content || draftView.content
      const contentSections = viewContent.blocks || viewContent.sections
      let updatedContentSections = contentSections

      for (var i = 0; i < contentSections.length; i += 1) {
        const sectionContent = contentSections[i].data?.content || contentSections[i].content

        const updatedContentSectionContent =
          updatedContentSections[i].data?.content || updatedContentSections[i].content

        sectionContent.forEach((contentBlock: any, contentBlockIndex: number) => {
          if (contentBlock.id === id) {
            updatedContentSectionContent.splice(contentBlockIndex, 1, updatedContentBlock)
          }
        })
      }

      onViewChange(updatedContentSections)
    }
  }

  const updateSection = (id: string, updatedSection: any) => {
    if (draftView.content) {
      const viewContent = draftView.data?.content || draftView.content
      const contentSections = viewContent.blocks || viewContent.sections
      let updatedContentSections = contentSections

      for (var i = 0; i < contentSections.length; i += 1) {
        if (contentSections[i].id === id) {
          contentSections[i] = updatedSection
        }
      }

      onViewChange(updatedContentSections)
    }
  }

  const ResultViewContentWrappers: ResultViewContentWrappers = {
    SectionsWrapper,
    SectionContentsWrapper,
    SectionWrapper,
    sectionOverlayActions: {
      onEditButtonClicked: onSectionEditButtonClicked,
      onCloneButtonClicked: onSectionCloneButtonClicked,
      onDeleteButtonClicked: onSectionDeleteButtonClicked,
    },
    BlockContentWrapper,
    blockContentOverlayActions: {
      onEditButtonClicked: onBlockContentEditButtonClicked,
      onCloneButtonClicked: onBlockContentCloneButtonClicked,
      onDeleteButtonClicked: onBlockContentDeleteButtonClicked,
    },
  }

  const onViewChange = (updatedBlocks: any) => {
    const updatedView = Object.assign({}, draftView, { content: { ...draftView.content, sections: updatedBlocks } })
    dispatch(setDraftView(updatedView))
  }

  const onDragEnd = (event: any) => {
    if (draftView.content) {
      if (event.destination) {
        const viewContent = draftView.data?.content || draftView.content

        const contentSections = viewContent.blocks || viewContent.sections

        const { draggableId } = event

        const dragSource = {
          droppableId: event.source.droppableId,
          index: event.source.index,
        }
        const dragDestination = {
          droppableId: event.destination.droppableId,
          index: event.destination.index,
        }

        let updatedContentSections = contentSections
        let movingElement
        switch (event.type) {
          case 'SECTIONS':
            if (draggableId === 'new-section') {
              // it's a new section so add it
              const newSection = getDefaultContentBlockForType('section')

              updatedContentSections.splice(dragDestination.index, 0, newSection)
              onViewChange(updatedContentSections)

              const newSectionId: any = newSection?.id

              setTabIndex(1)
              setEditingSection(newSectionId)
              setEditingContentBlock(0)
            } else {
              // it's an existing section so move it
              movingElement = contentSections.splice(dragSource.index, 1)[0]
              updatedContentSections.splice(dragDestination.index, 0, movingElement)
              onViewChange(updatedContentSections)
            }

            break
          case 'CONTENT':
            if (draggableId.indexOf('new-content-block-') === 0) {
              // it's a new content block so add it
              const newContentBlockType: BlockType = draggableId.split('new-content-block-')[1]
              const newContentElement = getDefaultContentBlockForType(newContentBlockType)

              for (var j = 0; j < contentSections.length; j += 1) {
                const updatedContentSectionsContent =
                  updatedContentSections[j].data?.content || updatedContentSections[j].content

                if (contentSections[j].id === dragDestination.droppableId) {
                  updatedContentSectionsContent.splice(dragDestination.index, 0, newContentElement)
                }
              }

              const newContentElementId: any = newContentElement?.id

              setTabIndex(1)
              setEditingSection(0)
              setEditingContentBlock(newContentElementId)
            } else {
              // it's an existing content block so move it
              for (var i = 0; i < contentSections.length; i += 1) {
                if (contentSections[i].id === dragSource.droppableId) {
                  movingElement = updatedContentSections[i].data
                    ? updatedContentSections[i].data.content.splice(dragSource.index, 1)[0]
                    : updatedContentSections[i].content.splice(dragSource.index, 1)[0]
                }
              }

              for (var k = 0; k < contentSections.length; k += 1) {
                if (contentSections[k].id === dragDestination.droppableId) {
                  const updatedContentSectionContent =
                    updatedContentSections[k].data?.content || updatedContentSections[k].content

                  updatedContentSectionContent.splice(dragDestination.index, 0, movingElement)
                }
              }
            }

            onViewChange(updatedContentSections)
            break
        }
      }
    }
  }

  const closeCurrentBlock = () => {
    setEditingContentBlock(0)
  }

  const closeCurrentSection = () => {
    setEditingSection(0)
  }

  return (
    <>
      {draftView?.id === view?.id && (
        <>
          <ResultViewEditorWrapper onDragEnd={onDragEnd}>
            <Form key={'atlaskit-form'} onSubmit={submitButtonAction}>
              {({ formProps }) => (
                <form key={'form'} {...formProps} onKeyPress={onKeyPress}>
                  <div style={{ display: 'flex' }}>
                    <div className="result-view-editor" style={{ minWidth: '375px' }}>
                      <div style={{ position: 'fixed', top: '135px' }}>
                        {(draftView.product_image_asset || draftView.product_image_url) &&
                          (draftView.logo_image_asset || draftView.logo_image_url) && (
                            <div
                              style={{
                                width: '375px',
                                maxHeight: 'calc(100vh - 185px)',
                                overflow: 'auto',
                                boxShadow: '0px 0 20px lightgrey',
                                float: 'left',
                              }}
                            >
                              <ResultView
                                key={'result-view'}
                                data={draftView}
                                contentWrappers={ResultViewContentWrappers}
                              ></ResultView>
                            </div>
                          )}
                      </div>
                    </div>

                    <div
                      style={{
                        width: '62%',
                        padding: '0 60px',
                        right: '0',
                        position: 'relative',

                        minWidth: '500px',
                        maxWidth: '800px',
                      }}
                    >
                      <PageHeader actions={actions} key={'page-header'}>
                        Editing Result View: {draftView.name}
                      </PageHeader>

                      <InlineMessage type="info" title="Help">
                        <a
                          href="https://developers.laava.id/docs/platform-concepts/result-views"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Click here to check out our documentation
                        </a>
                      </InlineMessage>

                      <Tabs
                        key="result-view-editor-tabs"
                        id="content-editor-tabs"
                        selectedIndex={tabIndex}
                        onSelect={(index) => setTabIndex(index)}
                      >
                        <TabList>
                          <Tab>Product Info</Tab>
                          <Tab>Display Content</Tab>
                          <Tab>Advanced View</Tab>
                        </TabList>

                        <TabPanel key="tab-panel-1">
                          <FieldRow>
                            <div key={'display-state-text'} style={{ width: '100%' }}>
                              <Field
                                key={'display_state'}
                                name="display_state"
                                label="Display State"
                                isRequired
                                defaultValue={displayState}
                              >
                                {({ fieldProps, error }) => (
                                  <TextField
                                    placeholder="Genuine"
                                    key={'display-state-textfield'}
                                    {...fieldProps}
                                    value={displayState}
                                    onSubmit={handleProductInfoFormFieldChange}
                                    onBlur={handleProductInfoFormFieldChange}
                                    onChange={handleProductInfoFormFieldChange}
                                  />
                                )}
                              </Field>
                            </div>
                            <ColorPicker
                              color={draftView?.style?.state_colour}
                              setColor={(value: any) => {
                                handleProductInfoFormFieldChange({
                                  target: { name: 'style.state_colour', value },
                                })
                              }}
                              key={'color-picker-state_colour'}
                            />
                          </FieldRow>

                          <FieldRow>
                            <div key={'display-description-text'} style={{ width: '100%' }}>
                              <Field
                                key={'display_description'}
                                name="display_description"
                                label="Display Description"
                                isRequired
                                defaultValue={displayDescription}
                              >
                                {({ fieldProps, error }) => (
                                  <TextField
                                    placeholder="Product Name"
                                    key={'display-description-textfield'}
                                    {...fieldProps}
                                    value={displayDescription}
                                    onSubmit={handleProductInfoFormFieldChange}
                                    onBlur={handleProductInfoFormFieldChange}
                                    onChange={handleProductInfoFormFieldChange}
                                  />
                                )}
                              </Field>
                            </div>
                            <ColorPicker
                              color={draftView?.style?.description_colour}
                              setColor={(value: any) => {
                                handleProductInfoFormFieldChange({
                                  target: { name: 'style.description_colour', value },
                                })
                              }}
                              key={'color-picker-display_description'}
                            />
                          </FieldRow>
                          <FieldRow>
                            <div key={'display-details-text'} style={{ width: '100%' }}>
                              <Field
                                key={'display_details'}
                                name="display_details"
                                label="Display Details"
                                isRequired
                                defaultValue={displayDetails}
                              >
                                {({ fieldProps, error }) => (
                                  <TextField
                                    placeholder="This has been classified as genuine, as it meets our expected scanning patterns and activity."
                                    key={'display-details-textfield'}
                                    {...fieldProps}
                                    value={displayDetails}
                                    maxLength={255}
                                    onSubmit={handleProductInfoFormFieldChange}
                                    onBlur={handleProductInfoFormFieldChange}
                                    onChange={handleProductInfoFormFieldChange}
                                  />
                                )}
                              </Field>
                            </div>
                            <ColorPicker
                              color={draftView?.style?.details_colour}
                              setColor={(value: any) => {
                                handleProductInfoFormFieldChange({
                                  target: { name: 'style.details_colour', value },
                                })
                              }}
                              key={'color-picker-display_details'}
                            />
                          </FieldRow>

                          <br />
                          <br />
                          <ColorsRow>
                            <Col md={3}>
                              <h6>Arrow</h6>
                              <br />
                              <div style={{ margin: 'auto' }}>
                                <ColorPicker
                                  color={draftView?.style?.arrow_colour}
                                  setColor={(value: any) => {
                                    handleProductInfoFormFieldChange({
                                      target: { name: 'style.arrow_colour', value },
                                    })
                                  }}
                                  key={'color-picker-arrow_colour'}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <h6>Background</h6>
                              <br />
                              <div style={{ margin: 'auto' }}>
                                <ColorPicker
                                  color={draftView?.style?.background_colour}
                                  setColor={(value: any) => {
                                    handleProductInfoFormFieldChange({
                                      target: { name: 'style.background_colour', value },
                                    })
                                  }}
                                  key={'color-picker-background_colour'}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <h6>Icon Background</h6>
                              <br />
                              <div style={{ margin: 'auto' }}>
                                <ColorPicker
                                  color={draftView?.style?.icon_background_colour}
                                  setColor={(value: any) => {
                                    handleProductInfoFormFieldChange({
                                      target: { name: 'style.icon_background_colour', value },
                                    })
                                  }}
                                  key={'color-picker-icon_background_colour'}
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <Tooltip
                                content="Configure this when you have a transparent product image."
                                position="top"
                              >
                                <h6>Product Image Background</h6>
                              </Tooltip>
                              <br />
                              <div style={{ margin: 'auto' }}>
                                <ColorPicker
                                  color={draftView?.style?.product_image_background_colour}
                                  setColor={(value: any) => {
                                    handleProductInfoFormFieldChange({
                                      target: { name: 'style.product_image_background_colour', value },
                                    })
                                  }}
                                  key={'color-picker-product_image_background_colour'}
                                />
                              </div>
                            </Col>
                          </ColorsRow>
                          <br />
                          <br />
                          <Container>
                            <Row style={{ padding: 'unset' }}>
                              <Col sm={3} style={{ paddingLeft: 'unset' }}>
                                <Field name="iconImageOption" label="Icon" isRequired defaultValue={iconImageOption}>
                                  {({ fieldProps, error }) => (
                                    <RadioGroup
                                      value={iconImageOption}
                                      options={[
                                        { name: 'iconImage', value: 'default', label: 'White tick (default)' },
                                        { name: 'iconImage', value: 'custom', label: 'Custom' },
                                      ]}
                                      onChange={(event) => {
                                        setIconImageOption(event.currentTarget.value)
                                        if (event.currentTarget.value === 'default') {
                                          handleProductInfoFormFieldChange({
                                            target: { name: 'icon_image_asset_id', value: null },
                                          })
                                          handleProductInfoFormFieldChange({
                                            target: { name: 'icon_image_url', value: null },
                                          })
                                        }
                                      }}
                                      aria-labelledby="radiogroup-label"
                                    />
                                  )}
                                </Field>
                                <br />
                                <br />
                              </Col>
                              <Col sm={3} style={{ paddingLeft: 'unset' }}>
                                {iconImageOption === 'custom' && (
                                  <AssetSelector
                                    key={'icon_image_asset_id'}
                                    onSubmitAsset={(value: any) => {
                                      // if it's null, then the image has been cleared and the default should be restored
                                      if (!value) {
                                        handleProductInfoFormFieldChange({
                                          target: { name: 'icon_image_asset_id', value: null },
                                        })
                                        handleProductInfoFormFieldChange({
                                          target: {
                                            name: 'icon_image_url',
                                            value: null,
                                          },
                                        })
                                      } else {
                                        handleProductInfoFormFieldChange({
                                          target: { name: 'icon_image_asset_id', value },
                                        })
                                      }
                                    }}
                                    currentAssetId={draftView.icon_image_asset?.id}
                                    project={project}
                                    isOpen={openAssetSelectorId === 'icon_image_asset_id'}
                                    setIsOpen={() => {
                                      setOpenAssetSelectorId('icon_image_asset_id')
                                    }}
                                    setIsClosed={() => {
                                      setOpenAssetSelectorId('')
                                    }}
                                  />
                                )}
                              </Col>
                            </Row>
                            <Row style={{ padding: 'unset' }}>
                              <Col sm={6} md={6} style={{ padding: 'unset' }}>
                                <h6>Product image</h6>
                                <br />
                              </Col>
                              <Col sm={6} md={6} style={{ padding: 'unset' }}>
                                <h6>Logo</h6>
                              </Col>
                            </Row>
                            <Row style={{ padding: 'unset' }}>
                              <Col sm={6} md={6} style={{ paddingLeft: 'unset' }}>
                                <AssetSelector
                                  key={'product_image_asset_id'}
                                  onSubmitAsset={(value: any) => {
                                    // if it's null, then the image has been cleared and the default should be restored
                                    if (!value) {
                                      handleProductInfoFormFieldChange({
                                        target: { name: 'product_image_asset_id', value: null },
                                      })
                                      handleProductInfoFormFieldChange({
                                        target: {
                                          name: 'product_image_url',
                                          value: 'https://via.placeholder.com/500?text=PRODUCT+IMAGE',
                                        },
                                      })
                                    } else {
                                      handleProductInfoFormFieldChange({
                                        target: { name: 'product_image_asset_id', value },
                                      })
                                    }
                                  }}
                                  currentAssetId={draftView.product_image_asset?.id}
                                  project={project}
                                  isOpen={openAssetSelectorId === 'product_image_asset_id'}
                                  setIsOpen={() => {
                                    setOpenAssetSelectorId('product_image_asset_id')
                                  }}
                                  setIsClosed={() => {
                                    setOpenAssetSelectorId('')
                                  }}
                                />
                                <br />
                                <div key={'product-image-url-text'} style={{ width: '100%' }}>
                                  <Field
                                    key={'product_image_url'}
                                    name="product_image_url"
                                    label="Product Image URL"
                                    defaultValue={productImageUrl}
                                  >
                                    {({ fieldProps, error }) => (
                                      <Fragment>
                                      <TextField
                                        placeholder=""
                                        key={'product-image-url-textfield'}
                                        {...fieldProps}
                                        value={productImageUrl}
                                        maxLength={255}
                                        onSubmit={handleProductInfoFormFieldChange}
                                        onBlur={handleProductInfoFormFieldChange}
                                        onChange={handleProductInfoFormFieldChange}
                                      />
                                      <HelperMessage>Any URL here will override an asset selected above.</HelperMessage>
                                      </Fragment>
                                    )}
                                  </Field>
                                </div>
                                <br />

                                <Select
                                  defaultValue={
                                    productImageScaleModes.filter(
                                      (mode: any) =>
                                        mode.value === draftView?.style?.product_image_scale_mode ||
                                        mode.value === 'contain',
                                    )[0]
                                  }
                                  onChange={(value: any) => {
                                    handleProductInfoFormFieldChange({
                                      target: { name: 'style.product_image_scale_mode', value: value.value },
                                    })
                                  }}
                                  key={'product_image_scale_mode'}
                                  name="product_image_scale_mode"
                                  options={productImageScaleModes}
                                  placeholder="Select a scale mode"
                                />
                              </Col>
                              <Col sm={6} md={6} style={{ paddingLeft: 'unset' }}>
                                <AssetSelector
                                  key={'logo_image_asset_id'}
                                  onSubmitAsset={(value: any) => {
                                    // if it's null, then the image has been cleared and the default should be restored
                                    if (!value) {
                                      handleProductInfoFormFieldChange({
                                        target: { name: 'logo_image_asset_id', value: null },
                                      })
                                      handleProductInfoFormFieldChange({
                                        target: {
                                          name: 'logo_image_url',
                                          value: 'https://via.placeholder.com/180x50?text=LOGO',
                                        },
                                      })
                                    } else {
                                      handleProductInfoFormFieldChange({
                                        target: { name: 'logo_image_asset_id', value },
                                      })
                                    }
                                  }}
                                  currentAssetId={draftView.logo_image_asset?.id}
                                  project={project}
                                  isOpen={openAssetSelectorId === 'logo_image_asset_id'}
                                  setIsOpen={() => {
                                    setOpenAssetSelectorId('logo_image_asset_id')
                                  }}
                                  setIsClosed={() => {
                                    setOpenAssetSelectorId('')
                                  }}
                                />
                                <br />
                                <div key={'logo-image-url-text'} style={{ width: '100%' }}>
                                  <Field
                                    key={'logo_image_url'}
                                    name="logo_image_url"
                                    label="Logo Image URL"
                                    defaultValue={logoImageUrl}
                                  >
                                    {({ fieldProps, error }) => (
                                      <Fragment>
                                      <TextField
                                        placeholder=""
                                        key={'logo-image-url-textfield'}
                                        {...fieldProps}
                                        value={logoImageUrl}
                                        maxLength={255}
                                        onSubmit={handleProductInfoFormFieldChange}
                                        onBlur={handleProductInfoFormFieldChange}
                                        onChange={handleProductInfoFormFieldChange}
                                      />
                                      <HelperMessage>Any URL here will override an asset selected above.</HelperMessage>
                                      </Fragment>
                                    )}
                                  </Field>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                          {/* </EditViewTabPanelInner> */}
                        </TabPanel>

                        <TabPanel>
                          {editingSection ? (
                            <EditPanel
                              id={editingSection}
                              project={project}
                              assets={assets}
                              draftView={draftView}
                              onUpdate={updateSection}
                              onClose={closeCurrentSection}
                            />
                          ) : null}
                          {editingContentBlock ? (
                            <>
                              <EditPanel
                                id={editingContentBlock}
                                project={project}
                                assets={assets}
                                draftView={draftView}
                                onUpdate={updateContentBlock}
                                onClose={closeCurrentBlock}
                              />
                            </>
                          ) : null}
                          {!editingSection && !editingContentBlock ? <SectionAndBlockPalette /> : null}
                        </TabPanel>
                        <TabPanel>
                          <div>
                            <PageHeader key={'page-header-result-content'}>Raw View</PageHeader>
                            <p>If you are unsure what you are doing here, please take a backup of this content first.</p>
                            <br/>
                            <div style={{"border":"1px solid lightgray"}}>
                            <ContentJsonEditor
                              content={draftView}
                              setContent={(value: any) => {
                                handleProductInfoFormFieldChange({ target: { name: 'view', value } })
                              }}
                              key={'content-json-editor'}
                              setIsSavingEnabled={setIsSavingEnabled}
                            />
                            </div>
                          </div>
                        </TabPanel>
                      </Tabs>
                    </div>
                  </div>
                </form>
              )}
            </Form>
          </ResultViewEditorWrapper>
        </>
      )}
    </>
  )
}

export default connect(mapStateToProps)(ProjectEditView)
