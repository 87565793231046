import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import _ from 'lodash'
import moment from 'moment'
import { saveAs } from 'file-saver'
import styled from 'styled-components'

import { MdCalendarToday } from 'react-icons/md'
import { Parser, transforms } from 'json2csv'
import { MultiSelect } from "react-multi-select-component";
import DatePicker from 'react-date-picker'

import PageHeader from '@atlaskit/page-header'
import Select from '@atlaskit/select'
import Button from '@atlaskit/button'
import DownloadIcon from '@atlaskit/icon/glyph/download'
import Spinner from '@atlaskit/spinner'
import Textfield from '@atlaskit/textfield';

import { Collection, Project, ScanEvent, ScanEventVerbose, State } from '../../types'
import { fetchScanEvents } from '../../state/scan-events/actions'
import { fetchCollectionsIfNeeded } from '../../state/collections/actions'
import TotalCountBox from '../../components/react/analytics/total-count-box'
import UniqueCountBox from '../../components/react/analytics/unique-count-box'
import ScansByCityBox from '../../components/react/analytics/scans-by-city-box'
import MostScannedTable from '../../components/react/analytics/most-scanned-table'
import DailyScansBox from '../../components/react/analytics/daily-scans-box'
import FlaggedTable from '../../components/react/analytics/flagged-table'
import ScansByScannerTypeBox from '../../components/react/analytics/scans-by-scanner-type'
import ScanMap from '../../components/react/analytics/scan-map'
import "./utils/analytics-styles.css";

const SpinnerContainer = styled.div`
  width: 10%;
  margin-left: auto;
  margin-right: auto;
`

type ScanEventGeocoded = (ScanEventVerbose | ScanEvent) & {
  type?: string
  properties?: any
  geometry?: any
}

interface Props {
  scanEvents: ScanEventGeocoded[]
  metadataKeyOptions: any
  hookDataKeyOptions: any
  collections: Collection[]
  project: Project
  userRole?: string
  isFetching: boolean
  dispatch: any
}

const createCsv = (scanEventsFiltered: ScanEventGeocoded[], project: Project, collectionsById: any) => {
  const transformData = (scan: ScanEventGeocoded) => {

    const parsedAddressComponents: any = {}
    scan?.addressComponents?.map((addressComponent: any) => {
      if (!parsedAddressComponents.addressComponents) parsedAddressComponents.addressComponents = {}
      parsedAddressComponents.addressComponents[addressComponent['types'][0]] = addressComponent.short_name
    })
    
    const parsedHookData: any = {}
    scan?.hookData && Object.keys(scan?.hookData).forEach(key => {
      if (!parsedHookData.hookData) parsedHookData.hookData = {}
      parsedHookData.hookData[key] = scan.hookData[key]?.response?.data
    })

    const parsedTagMetaData: any = {}
    scan?.tagMetadata && Object.keys(scan?.tagMetadata).forEach(key => {
      if (!parsedTagMetaData.tagMetadata) parsedTagMetaData.tagMetadata = {}
      parsedTagMetaData.tagMetadata[key] = scan.tagMetadata[key]
    })

    return {
      id: scan.id,
      tagId: scan.tagId,
      collection: scan.collectionId && collectionsById[scan.collectionId]?.name,
      collectionId: scan.collectionId,
      collectionCreatedDate: scan.collectionId && collectionsById[scan.collectionId]?.createdAt,
      project: project.name,
      projectId: project.id,
      createdAt: scan.createdAt,
      ruleStatus: scan.ruleStatus,
      gpsEnabled: scan.geolocationEnabled,
      clientLocationLat: scan.clientLocationLat,
      clientLocationLong: scan.clientLocationLong,
      city: scan.city,
      country: scan.country,
      clientAgent: scan.clientAgent,
      "userAgent.platform": scan.userAgent?.platform,
      "userAgent.browser": scan.userAgent?.browser,
      "userAgent.browserVersion": scan.userAgent?.version,
      ...parsedTagMetaData,
      ...parsedHookData,
      ...parsedAddressComponents,
    };
  };
  const parser = new Parser({
    transforms: [transformData, transforms.flatten({ objects: true, arrays: true })]
  })

  const csv = parser.parse(scanEventsFiltered);
  saveAs(new Blob([csv], {type: "text/csv;charset=utf-8"}), `${project.name}.csv`)
}

const mapStateToProps = (state: State, ownProps: any) => {
  const project = state.projects.items.filter(
    (project: Project) => project.id === ownProps.match.params.projectId,
  )[0]

  const distinctMetadataKeys = new Set()
  const distinctHookDataKeys = new Set()

  const scanEvents: ScanEvent[] = state.scanEvents.items
    .map((scanEvent) => {
      if (!scanEvent.city || scanEvent.city.length <= 1) {
        scanEvent.city = 'Unknown'
      }
      if (!scanEvent.country || scanEvent.country.length <= 1) {
        scanEvent.country = 'Unknown'
      }
      scanEvent?.tagMetadata && Object.keys(scanEvent.tagMetadata).map(key => distinctMetadataKeys.add(key))
      scanEvent?.hookData && Object.keys(scanEvent.hookData).map(key => scanEvent.hookData[key].response?.data && Object.keys(scanEvent.hookData[key].response?.data).map(key => distinctHookDataKeys.add(key)))
      if (scanEvent.clientLocationLong && scanEvent.clientLocationLat) {
        return {
          ...scanEvent,
          type: 'Feature',
          properties: {
            cluster: false,
          },
          geometry: {
            type: 'Point',
            coordinates: [scanEvent.clientLocationLong, scanEvent.clientLocationLat],
          },
        }
      } else {
        return scanEvent
      }
    })
    // Order by newest
    .sort((first, next) => {
      return new Date(next.createdAt).getTime() - new Date(first.createdAt).getTime()
    })
  
  let metadataKeyOptions: any[] = []
  distinctMetadataKeys.forEach((value) => {
    metadataKeyOptions.push({value, label: value})
  })

  let hookDataKeyOptions: any[] = []
  distinctHookDataKeys.forEach((value) => {
    hookDataKeyOptions.push({value, label: value})
  })

  return {
    scanEvents,
    metadataKeyOptions,
    hookDataKeyOptions,
    project,
    collections: state.collections.items,
    isFetching: state.scanEvents.isFetching
  }
}

const ProjectAnalytics: React.FC<Props> = ({ scanEvents, metadataKeyOptions, hookDataKeyOptions, project, collections, isFetching, dispatch }) => {
  const [scanEventsFiltered, setScanEventsFiltered] = useState<ScanEventGeocoded[]>([])
  const [cityFilters, setCityFilters] = useState<string[]>([])
  const [countryFilters, setCountryFilters] = useState<string[]>([])
  const [locationTypeFilters, setLocationTypeFilters] = useState<string[]>(['gps', 'ipAddress'])
  const [collectionFilters, setCollectionFilters] = useState<any[]>([])
  const [clientAgentFilters, setClientAgentFilters] = useState<string[]>([])
  const [tagIdFilters, setTagIdFilters] = useState<string>()
  const [dateFilter, setDateFilter] = useState<any>()
  const [metadataKeyFilters, setMetadataKeyFilters] = useState<string>()
  const [metadataValueFilters, setMetadataValueFilters] = useState<string>()
  const [hookDataKeyFilters, setHookDataKeyFilters] = useState<string>()
  const [hookDataValueFilters, setHookDataValueFilters] = useState<string>()

  // Map State
  const [popup, setPopup]: any = useState(undefined)
  const [mapCenter, setMapCenter] = useState<[number, number]>([0, 0])
  const [isMaxZoom, setIsMaxZoom] = useState<boolean>(false)


  useEffect(() => {
    dispatch(fetchCollectionsIfNeeded(project?.id))
  })

  useEffect(() => {
    dispatch(
      fetchScanEvents({
        projectId: project?.id,
        // TODO: Optimise fetchScanEvents performance to support using images from detectData for Super Users.
        // include: 'detectData', 
      }),
    )
  }, [dispatch, project])

  const setCityFilter = (citiesInput: any) => {
    setCityFilters(
      citiesInput?.map((input: any) => {
        return input.value
      }),
    )
  }

  const setCountryFilter = (countriesInput: any) => {
    setCountryFilters(
      countriesInput?.map((input: any) => {
        return input.value
      }),
    )
  }

  const setLocationTypeFilter = (locationTypes: any) => {
    setLocationTypeFilters(
      locationTypes?.map((input: any) => {
        return input.value
      }),
    )
  }

  const setCollectionFilter = (collectionsInput: any) => {
    setCollectionFilters(collectionsInput)
  }

  const setClientAgentFilter = (clientAgentsInput: any) => {
    setClientAgentFilters(
      clientAgentsInput?.map((input: any) => {
        return input.value
      }),
    )
  }

  const setTagIdFilter = (tagIdsInput: any) => {
    setTagIdFilters(tagIdsInput.target?.value)
  }

  const setMetadataKeyFilter = (metadataKey: any) => {
    setMetadataKeyFilters(metadataKey?.value)
  }

  const setMetadataValueFilter = _.debounce((metadataValue: any) => {
    setMetadataValueFilters(metadataValue.target?.value)
  }, 300)

  const setHookDataKeyFilter = (hookDataKey: any) => {
    setHookDataKeyFilters(hookDataKey?.value)
  }

  const setHookDataValueFilter = _.debounce((hookDataValue: any) => {
    setHookDataValueFilters(hookDataValue.target?.value)
  }, 300)

  useEffect(() => {
    let eventsToFilter = scanEvents

    if (countryFilters?.length > 0) {
      eventsToFilter = eventsToFilter.filter(scanEvent => countryFilters?.includes(scanEvent.country as string))
    }
    if (cityFilters?.length > 0) {
      eventsToFilter = eventsToFilter.filter(scanEvent => cityFilters?.includes(scanEvent.city as string))
    }
    if (locationTypeFilters?.length > 0) {
      eventsToFilter = eventsToFilter.filter(scanEvent => (locationTypeFilters?.includes('gps') && scanEvent.geolocationEnabled) || (locationTypeFilters?.includes('ipAddress') && !scanEvent.geolocationEnabled))
    }
    if (collectionFilters?.length > 0) {
      eventsToFilter = eventsToFilter.filter(scanEvent => collectionFilters?.some(filter => filter.value === scanEvent.collectionId as string))
    }
    if (clientAgentFilters?.length > 0) {
      eventsToFilter = eventsToFilter.filter(scanEvent => clientAgentFilters?.includes(scanEvent.clientAgent as string))
    }
    if (tagIdFilters) {
      eventsToFilter = eventsToFilter.filter(scanEvent => scanEvent.tagId === tagIdFilters)
    }
    if (dateFilter) {
      eventsToFilter = eventsToFilter.filter(scanEvent => moment(scanEvent.createdAt).isAfter(dateFilter[0]) && moment(scanEvent.createdAt).isBefore(dateFilter[1]))
    }

    if (metadataKeyFilters && metadataValueFilters) {
      eventsToFilter = eventsToFilter.filter((scanEvent) => scanEvent?.tagMetadata && (scanEvent.tagMetadata[metadataKeyFilters]?.toLowerCase() === metadataValueFilters?.toLowerCase()))
    }

    if (hookDataKeyFilters && hookDataValueFilters) {
      eventsToFilter = eventsToFilter.filter((scanEvent) => scanEvent?.hookData && Object.keys(scanEvent.hookData).filter(key => ((scanEvent.hookData[key].response.data[hookDataKeyFilters]).toLowerCase() === (hookDataValueFilters).toLowerCase())).length > 0)
    }

    // Map logic
    setPopup(undefined)
    setIsMaxZoom(false)

    setScanEventsFiltered(eventsToFilter)
  }, [cityFilters, countryFilters, collectionFilters, clientAgentFilters, dateFilter, scanEvents, tagIdFilters, locationTypeFilters, metadataKeyFilters, metadataValueFilters, hookDataKeyFilters, hookDataValueFilters])
  
  const collectionsById = useMemo(() => {
    return collections.reduce((indexedCollections: { [key: string]: Collection }, collection) => {
      if (!(collection.id in indexedCollections)) {
        indexedCollections[collection.id] = collection
      }
      return indexedCollections
    }, {})
  }, [collections])

 const countriesOptions = useMemo(() => {
    return _.uniqBy(scanEvents, 'country').map((scan) => {
      return { label: scan.country, value: scan.country }
    })
  }, [scanEvents])

  const citiesOptions = useMemo(() => {
   return _.uniqBy(scanEventsFiltered, 'city').map((scan) => {
    return { label: scan.city, value: scan.city }
    })
  }, [scanEventsFiltered])

  const collectionNameOptions = useMemo(() => {
    return  _.uniqBy(scanEvents, 'collectionId').map((scan) => {
        return { label: collectionsById[scan.collectionId as string]?.name, value: scan.collectionId }
      })
  }, [collectionsById, scanEvents])

  const downloadCSVData = () => {createCsv(scanEventsFiltered, project, collectionsById)}
  const headerActions = (<Button
    iconBefore={<DownloadIcon label="" />}
    style={{ height: '40px', width: '100%', justifyContent: 'center' }}
    onClick={downloadCSVData}
  >
    Download Data
  </Button>
  )

  return (
    <>
      <PageHeader actions={headerActions}>
        Analytics
      </PageHeader>

      <Container fluid style={{ padding: 'unset' }}>
        <Row>
          <Col sm={2}>
            <Select
              options={countriesOptions}
              isMulti={true}
              onChange={setCountryFilter}
              placeholder="Country"
            />
          </Col>

          <Col sm={2}>
            <Select
              options={citiesOptions}
              isMulti={true}
              onChange={setCityFilter}
              placeholder="City"
            />
          </Col>

          <Col sm={2}>
            <Select
              options={[
                { label: 'GPS', value: 'gps' },
                { label: 'IP Address', value: 'ipAddress' },
              ]}
              isMulti={true}
              onChange={(inputValue) => {
                inputValue !== null ? setLocationTypeFilter(inputValue) : setLocationTypeFilter([])
              }}
              placeholder="Location Type"
            />
          </Col>

          <Col sm={2}>
            <DatePicker
              onChange={setDateFilter}
              className={dateFilter ? "datePicker" : "datePickerEmpty"}
              selectRange={true}
              format="d/M/y"
              dayPlaceholder='Date Range'
              value={dateFilter}
              calendarIcon={<MdCalendarToday color='#42526E' />}
              clearIcon={dateFilter ? undefined : null}
            ></DatePicker>
          </Col>

          <Col sm={2}>
            <Select
              options={metadataKeyOptions}
              onChange={setMetadataKeyFilter}
              placeholder="Metadata Key"
              isClearable
            />
          </Col>

          <Col sm={2}>
            <Textfield label="Metadata Value" placeholder="Metadata Value" onChange={setMetadataValueFilter} />
          </Col>

        </Row>
        <br />
        <Row>
          <Col sm={3}>
          <MultiSelect
            options={collectionNameOptions}
            value={collectionFilters}
            onChange={setCollectionFilter}
            labelledBy="Collection"
            overrideStrings={{"selectSomeItems": "Collection"}}
          />
          </Col>

          <Col sm={3}>
          <Textfield label="Fingerprint ID" placeholder="Fingerprint ID" onChange={setTagIdFilter} />
          </Col>

          <Col sm={2}>
          <Select
              options={[
                { label: 'WeChat', value: 'WECHAT' },
                { label: 'Webscanner', value: 'WEBSCANNER' },
                { label: 'iOS', value: 'NATIVESCANNER_IOS' },
                { label: 'Android', value: 'NATIVESCANNER_ANDROID' },
                { label: 'WhatsApp', value: 'WHATSAPP' },
                { label: 'Other', value: null },
              ]}
              isMulti={true}
              onChange={setClientAgentFilter}
              placeholder="Scan Platform"
            />
          </Col>

          <Col sm={2}>
            <Select
            options={hookDataKeyOptions}
            onChange={setHookDataKeyFilter}
            placeholder="Hook Data Key"
            isClearable
            />
          </Col>

          <Col sm={2}>
          <Textfield label="Hook Data Value" placeholder="Hook Data Value" onChange={setHookDataValueFilter} />
          </Col>
        </Row>
        <br />
        {isFetching ? (
          <SpinnerContainer>
            <Spinner size="xlarge" />
          </SpinnerContainer>
        ) : (
          <>
            <Row>
              <Col sm={6}>
                <TotalCountBox data={scanEventsFiltered} />
              </Col>
              <Col sm={6}>
                <UniqueCountBox data={scanEventsFiltered} />
              </Col>
            </Row>
            <br />
            <Row style={{ border: '2px solid #dfe1e5', borderRadius: '3px', margin: 0 }}>
              <ScanMap mapCenter={mapCenter} setMapCenter={setMapCenter} scans={scanEventsFiltered} popup={popup} setPopup={setPopup} isMaxZoom={isMaxZoom} setIsMaxZoom={setIsMaxZoom} collectionsById={collectionsById} project={project} />
            </Row>
            <br />
            <Row>
              <Col sm={6}>
                <FlaggedTable scanEvents={scanEventsFiltered} />
              </Col>
              <Col sm={6}>
                <MostScannedTable scanEvents={scanEventsFiltered} />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={6}>
                <ScansByCityBox scanEvents={scanEventsFiltered} />
              </Col>
              <Col sm={6}>
                <ScansByScannerTypeBox scanEvents={scanEventsFiltered} />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={12}>
                <DailyScansBox scanEvents={scanEventsFiltered} />
              </Col>
            </Row>
          </>
        )}
        <br />
      </Container>
    </>
  )
}

export default connect(mapStateToProps)(ProjectAnalytics)
