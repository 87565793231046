import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import { ErrorMessage } from '@atlaskit/form'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-github'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/webpack-resolver'

interface ContentJsonEditorProps {
  content: any
  setContent: any
  setIsSavingEnabled: any
}

const protectedKeys = {
  content: {
    fonts: [],
    styles: {
      heading: {},
      subHeading: {},
      leadParagraph: {},
      paragraph: {},
      button: {},
      recipeTab: {},
    },
  },
}

const findMissingProtectedKeys = (objectToCheck: any) => {
  const missingProtectedKeys: string[] = []

  Object.keys(protectedKeys).filter((key) => {
    if (!Object.keys(objectToCheck).includes(key)) {
      return missingProtectedKeys.push(key)
    } else {
      return missingProtectedKeys
    }
  })

  return missingProtectedKeys
}

const ContentJsonEditor: React.FC<ContentJsonEditorProps> = ({ content, setContent, setIsSavingEnabled }) => {
  if (!content) {
    content = protectedKeys
  }
  const [customError, setCustomError] = useState<any>(false)
  const [contentEdited, setContentEdited] = useState<any>(JSON.stringify(content, null, 4))
  // const [savingEnabled, setSavingEnabled] = useState<boolean>(true)

  useEffect(() => {
    const missingProtectedKeys = findMissingProtectedKeys(content)
    // Use this hook to ensure state is valid from parent updates, e.g. from a reset
    if (missingProtectedKeys.length < 1) {
      setCustomError(false)
    } else {
      setCustomError(`JSON is missing the required keys: ${missingProtectedKeys}`)
      return
    }

    setContentEdited(JSON.stringify(content, null, 4))

    setIsSavingEnabled(true)

    const sectionsMissingIds: any[] = []
    const contentBlocksMissingIds: Map<number, number[]> = new Map()
    const sectionIds: string[] = []

    // check for duplicate section IDs

    const duplicateSectionIds = new Set()

    sectionIds.forEach((sectionId) => {
      if (sectionIds.filter((s) => s === sectionId).length > 1) {
        duplicateSectionIds.add(sectionId)
      }
    })

    if (sectionsMissingIds.length > 0) {
      setCustomError(
        `Section${sectionsMissingIds.length > 1 ? 's' : ''} ${sectionsMissingIds.join(',')} missing ${
          sectionsMissingIds.length === 1 ? 'an ' : ''
        }ID${sectionsMissingIds.length > 1 ? 's' : ''}. Each section must have its own unique ID.`,
      )
      setIsSavingEnabled(false)
      // return
    }

    if (contentBlocksMissingIds.size > 0) {
      let missingIdDetails = ``

      contentBlocksMissingIds.forEach((value, key) => {
        if (value.length > 0) {
          missingIdDetails += ` Section ${key}: [${value.join(',')}]`
        }
      })

      setCustomError(`Missing content block IDs: ${missingIdDetails}`)
      setIsSavingEnabled(false)
      // return
    }

    if (duplicateSectionIds.size > 0) {
      setCustomError(
        `Duplication section ID${duplicateSectionIds.size > 1 ? 's' : ''} found: ${Array.from(duplicateSectionIds).join(
          ',',
        )}`,
      )
      setIsSavingEnabled(false)
      // return
    }
  }, [content, setIsSavingEnabled])

  const update = (contentInput?: any) => {
    try {
      // Find any missing keys

      const contentJSON = JSON.parse(contentInput)

      const missingProtectedKeys = findMissingProtectedKeys(contentJSON)

      if (missingProtectedKeys.length < 1) {
        setCustomError(false)
        // setContentEdited(contentInput)
        setContent(JSON.parse(contentInput))
      } else {
        // Can add error callback here to ensure validation before form submissions
        // setContentEdited(contentInput)
        setCustomError(`JSON is missing the required keys: ${missingProtectedKeys}. Invalid JSON will not be saved.`)
        console.log('Content Json Editor: Invalid JSON')
        return
      }

      const contentInputJSON = JSON.parse(contentInput)
      // remove id, name and description
      const newContent = { ...contentInputJSON }
      delete newContent.id
      delete newContent.name
      delete newContent.description

      const fullContent = Object.assign({}, content, newContent)

      setContent(fullContent)
      setContentEdited(JSON.stringify({ newContent }, null, 4))
    } catch (error) {
      setCustomError(`Invalid JSON. Invalid JSON will not be saved.`)
      console.log('Content Json Editor: Invalid JSON')
      return
    }
  }

  const debounceEditorChange = _.debounce(update, 50)

  return (
    <>
      {customError && (
        <>
          <ErrorMessage>{customError}</ErrorMessage>
          <br />
        </>
      )}
      <AceEditor
        width={'100%'}
        height={'1000px'}
        mode={'json'}
        onChange={debounceEditorChange}
        value={contentEdited}
        theme="github"
        name="editor"
        key={'ace-json-editor'}
        onLoad={(editor) => {
          const session = editor.getSession()
          const undoManager = session.getUndoManager()
          undoManager.reset()
          session.setUndoManager(undoManager)
        }}
      />
    </>
  )
}

export default ContentJsonEditor
