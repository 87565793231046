import axios from 'axios'
import FormData from 'form-data'

// VIEWS
export const VIEWS_REQUEST = 'VIEWS_REQUEST'
export const VIEWS_RECEIVE = 'VIEWS_RECEIVE'
export const VIEWS_INVALIDATE = 'VIEWS_INVALIDATE'
export const VIEW_SET_DRAFT = 'VIEW_SET_DRAFT'

const viewsRequest = () => {
  return {
    type: VIEWS_REQUEST,
  }
}

const viewsReceive = (views: any) => {
  return {
    type: VIEWS_RECEIVE,
    views,
  }
}

export const viewsInvalidate = () => {
  return {
    type: VIEWS_INVALIDATE,
  }
}

export const fetchViews = (project_id: string) => {
  return async (dispatch: any) => {
    // TODO: We want to get scopes from AccMan instead via the proxy
    dispatch(viewsRequest())
    await axios
      .get(`${process.env.REACT_APP_CONTENT_BASE_URL}/views`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          project_id: project_id,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((result) => {
        dispatch(viewsReceive(result.data.views))
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

const shouldFetchViews = (state: any): boolean => {
  const lastFetched = state.content.views?.lastFetched
  const isFetching = state.content.views?.isFetching

  if (!isFetching) {
    if (!lastFetched) {
      return true
    } else {
      return state.content.views.didInvalidate
    }
  } else {
    return false
  }
}

export const fetchViewsIfNeeded = (project_id: string) => {
  return (dispatch: any, getState: any) => {
    if (project_id && shouldFetchViews(getState())) {
      dispatch(fetchViews(project_id))
    }
  }
}

const shouldSetDraftView = (state: any, view: any): boolean => {
  return view.id !== state.content.views.draft?.id
}

export const setDraftViewIfNeeded = (view: any) => {
  return (dispatch: any, getState: any) => {
    if (view && shouldSetDraftView(getState(), view)) {
      dispatch(setDraftView(view))
    }
  }
}

export const setDraftView = (view: any) => {
  return {
    type: VIEW_SET_DRAFT,
    view,
  }
}

interface CreateViewsParams {
  name: string
  description: string
  project_id: string
  logo_image_asset_id?: string
  logo_image_url?: string
  product_image_asset_id?: string
  product_image_url?: string
  display_state?: string
  display_description?: string
  display_details?: string
  content?: any
  style?: any
  html_content_url?: string
}

export const createView = ({
  name,
  description,
  project_id,
  logo_image_asset_id,
  logo_image_url,
  product_image_asset_id,
  product_image_url,
  display_state,
  display_description,
  display_details,
  content,
  style = {},
  html_content_url,
}: CreateViewsParams) => {
  return async (dispatch: any) => {
    await axios(`${process.env.REACT_APP_CONTENT_BASE_URL}/views`, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        project_id: project_id,
        'Content-Type': 'application/json',
      },
      data: {
        name,
        description,
        logo_image_asset_id,
        logo_image_url,
        product_image_asset_id,
        product_image_url,
        display_state,
        display_description,
        display_details,
        content,
        style,
        html_content_url,
      },
    })
      .then((result) => {
        // Error/success handling goes here?
        dispatch(viewsInvalidate())
        dispatch(fetchViews(project_id))
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

interface UpdateViewsParams {
  project_id: string
  id: string
  logo_image_asset_id?: string
  logo_image_url?: string
  logo_image_asset?: any
  product_image_asset_id?: string
  product_image_asset?: any
  icon_image_asset_id?: string
  product_image_url?: string
  display_state?: string
  display_description?: string
  display_details?: string
  content?: any
  style?: any
  html_content_url?: string
}

export const updateView = ({
  project_id,
  id,
  logo_image_asset_id,
  logo_image_url,
  logo_image_asset,
  product_image_asset_id,
  product_image_asset,
  icon_image_asset_id,
  product_image_url,
  display_state,
  display_description,
  display_details,
  content,
  style,
  html_content_url,
}: UpdateViewsParams) => {

  if (logo_image_asset && !logo_image_asset_id) {
    logo_image_asset_id = logo_image_asset.id
  }

  if (product_image_asset && !product_image_asset_id) {
    product_image_asset_id = product_image_asset.id
  }

  return (dispatch: any) => {
    axios({
      method: 'put',
      url: `${process.env.REACT_APP_CONTENT_BASE_URL}/views`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        project_id: project_id,
        'Content-Type': 'application/json',
      },
      data: {
        id,
        logo_image_asset_id,
        logo_image_url,
        product_image_asset_id,
        product_image_url,
        icon_image_asset_id,
        display_state,
        display_description,
        display_details,
        content,
        style,
        html_content_url,
      },
    })
      .then((result) => {
        // Error/success handling goes here?
        dispatch(viewsInvalidate())
        dispatch(fetchViews(project_id))
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

// COLLECTION VIEWS

export const COLLECTION_VIEWS_REQUEST = 'COLLECTION_VIEWS_REQUEST'
export const COLLECTION_VIEWS_RECEIVE = 'COLLECTION_VIEWS_RECEIVE'
export const COLLECTION_VIEWS_INVALIDATE = 'COLLECTION_VIEWS_INVALIDATE'

const collectionViewsRequest = () => {
  return {
    type: COLLECTION_VIEWS_REQUEST,
  }
}

const collectionViewsReceive = (collectionViews: any) => {
  return {
    type: COLLECTION_VIEWS_RECEIVE,
    collectionViews,
  }
}

export const collectionViewsInvalidate = () => {
  return {
    type: COLLECTION_VIEWS_INVALIDATE,
  }
}

export const fetchCollectionViews = (project_id: string, collection_id: string) => {
  return async (dispatch: any) => {
    dispatch(collectionViewsRequest())
    await axios
      .get(`${process.env.REACT_APP_CONTENT_BASE_URL}/collection-views/${collection_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          project_id: project_id,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((result) => {
        dispatch(collectionViewsReceive(result.data.collection_views))
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

const shouldFetchCollectionViews = (state: any): boolean => {
  const lastFetched = state.content.collectionViews?.lastFetched
  const isFetching = state.content.collectionViews?.isFetching

  if (!isFetching) {
    if (!lastFetched) {
      return true
    } else {
      return state.content.collectionViews.didInvalidate
    }
  } else {
    return false
  }
}

export const fetchCollectionViewsIfNeeded = (project_id: string, collection_id: string) => {
  return (dispatch: any, getState: any) => {
    if (project_id && collection_id && shouldFetchCollectionViews(getState())) {
      dispatch(fetchCollectionViews(project_id, collection_id))
    }
  }
}

interface CreateCollectionViewsParams {
  collectionId: string
  projectId: string
  viewId: string
  status: string
  platform: string
  language: string
  isDefault?: boolean
}

export const createCollectionView = ({
  collectionId,
  projectId,
  viewId,
  status,
  platform,
  language,
  isDefault,
}: CreateCollectionViewsParams) => {
  return async (dispatch: any) => {
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_CONTENT_BASE_URL}/collection-views`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        project_id: projectId,
        'Content-Type': 'application/json',
      },
      data: { collection_id:collectionId, view_id:viewId, state: status, platform, language, is_default:isDefault },
    })
      .then((result) => {
        // Error/success handling goes here?
        dispatch(collectionViewsInvalidate())
        dispatch(fetchCollectionViews(projectId, collectionId))
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

interface UpdateCollectionViewsParams {
  collectionId: string
  projectId: string
  viewId: string
  state: string
  platform: string
  language: string
  isDefault: boolean
}

export const updateCollectionView = ({
  collectionId,
  projectId,
  viewId,
  state,
  platform,
  language,
  isDefault,
}: UpdateCollectionViewsParams) => {
  return async (dispatch: any) => {
    await axios({
      method: 'put',
      url: `${process.env.REACT_APP_CONTENT_BASE_URL}/collection-views`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        project_id: projectId,
        'Content-Type': 'application/json',
      },
      data: { collection_id:collectionId, view_id:viewId, state, is_default:isDefault, platform, language },
    })
      .then((result) => {
        dispatch(collectionViewsInvalidate())
        dispatch(fetchCollectionViews(projectId, collectionId))
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

interface DeleteCollectionViewsParams {
  collection_view: {
    collection_id: string
    view_id: string
  }
  project_id: string
}

export const deleteCollectionView = ({ collection_view, project_id }: DeleteCollectionViewsParams) => {
  return async (dispatch: any) => {
    await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_CONTENT_BASE_URL}/collection-views`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        project_id: project_id,
        'Content-Type': 'application/json',
      },
      data: { ...collection_view },
    })
      .then((result) => {
        dispatch(collectionViewsInvalidate())
        dispatch(fetchCollectionViews(project_id, collection_view.collection_id))
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

// ASSETS

export const ASSETS_REQUEST = 'ASSETS_REQUEST'
export const ASSETS_RECEIVE = 'ASSETS_RECEIVE'
export const ASSETS_INVALIDATE = 'ASSETS_INVALIDATE'

const assetsRequest = () => {
  return {
    type: ASSETS_REQUEST,
  }
}

const assetsReceive = (assets: any) => {
  return {
    type: ASSETS_RECEIVE,
    assets,
  }
}

export const assetsInvalidate = () => {
  return {
    type: ASSETS_INVALIDATE,
  }
}

export const fetchAssets = (project_id: string) => {
  return async (dispatch: any) => {
    // TODO: We want to get scopes from AccMan instead via the proxy
    dispatch(assetsRequest())
    await axios
      .get(`${process.env.REACT_APP_CONTENT_BASE_URL}/assets`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          project_id: project_id,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((result) => {
        dispatch(assetsReceive(result.data.assets))
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

const shouldFetchAssets = (state: any): boolean => {
  const lastFetched = state.content.assets?.lastFetched
  const isFetching = state.content.assets?.isFetching

  if (!isFetching) {
    if (!lastFetched) {
      return true
    } else {
      return state.content.assets.didInvalidate
    }
  } else {
    return false
  }
}

export const fetchAssetsIfNeeded = (project_id: string) => {
  return (dispatch: any, getState: any) => {
    if (project_id && shouldFetchAssets(getState())) {
      dispatch(fetchAssets(project_id))
    }
  }
}

interface UploadAssetParams {
  projectId: string
  assetFile: File
  name: string
}

export const uploadAsset = ({ projectId, assetFile, name }: UploadAssetParams) => {
  return (dispatch: any) => {
    var data = new FormData()
    data.append('assetFile', assetFile)
    data.append('name', name)
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_CONTENT_BASE_URL}/assets`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        project_id: projectId,
        'Content-Type': 'multipart/form-data',
      },
      data,
    })
      .then((result) => {
        dispatch(assetsInvalidate())
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
