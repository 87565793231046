import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import Button from '@atlaskit/button'
import TrashIcon from '@atlaskit/icon/glyph/trash'
import PageHeader from '@atlaskit/page-header'
import DynamicTable from '@atlaskit/dynamic-table'
import { RadioGroup } from '@atlaskit/radio'
import { OptionsPropType } from '@atlaskit/radio/types'

import { State, Project, Collection, CollectionView, View } from '../../types'
import { dateFormatter, capitalizeFirstLetter, scanPlatformLabels, resultScreenLanguageLabels } from '../../helpers'
import NewCollectionViewModal from '../../components/react/modals/new-collection-view'
import {
  fetchCollectionViewsIfNeeded,
  createCollectionView,
  deleteCollectionView,
  fetchViewsIfNeeded,
  updateCollectionView,
} from '../../state/content/actions'
import { Restricted } from '../../providers/permission'

interface Props {
  user: any
  isFetching?: boolean
  collection: Collection
  project: Project
  collectionViews: CollectionView[]
  views: View[]
  dispatch: Function
}

interface OnSubmitNewCollectionViewProps {
  viewId: string
  status: string
  platform: string
  language: string
}

const mapStateToProps = (state: State, ownProps: any) => {
  const collection = state.collections.items.filter(
    (collection: Collection) => collection.id === ownProps.match.params.collectionId,
  )[0]
  const project = state.projects.items.filter((project: Project) => project.id === ownProps.match.params.projectId)[0]

  return {
    isFetching: state.collections.isFetching || state.content.collectionViews.isFetching,
    collection,
    project,
    collectionViews: state.content.collectionViews.items,
    views: state.content.views.items,
    user: state.account.login.user,
  }
}

const ResultScreens: React.FC<Props> = ({
  user,
  isFetching,
  collection,
  project,
  collectionViews,
  views,
  dispatch,
}) => {
  const [isNewCollectionViewModalOpen, setIsNewCollectionViewModalOpen] = useState(false)

  useEffect(() => {
    dispatch(fetchViewsIfNeeded(project.id))
    dispatch(fetchCollectionViewsIfNeeded(project.id, collection.id))
  })

  const onSubmitDeleteCollectionView = (collectionView: CollectionView) => {
    dispatch(deleteCollectionView({ collection_view: { ...collectionView }, project_id: project.id }))
  }

  const onSubmitNewCollectionView = ({ viewId, status, platform, language }: OnSubmitNewCollectionViewProps) => {
    const collectionId = collection.id
    const projectId = project.id

    return dispatch(
      createCollectionView({
        collectionId,
        projectId,
        viewId,
        status,
        platform,
        language,
      }),
    )
  }

  const collectionViewsHead = {
    cells: [
      {
        content: 'View',
        key: 'name',
        isSortable: true,
        height: 36,
      },
      {
        content: 'State',
      },
      {
        content: 'Platform',
        key: 'platform',
        isSortable: true,
      },
      {
        content: 'Language',
        key: 'language',
        isSortable: true,
      },
      {
        content: 'Default',
        key: 'default',
      },
      {
        content: 'Created at',
        key: 'created_at',
        isSortable: true,
      },
      {
        content: '',
        key: '',
      },
    ],
  }

  const collectionViewsCells = collectionViews?.map((collectionView: CollectionView) => {
    const defaultViewRadioOptions: OptionsPropType = [{ name: 'defaultView', value: 'yes', label: '' }]

    const onDefaultViewChange = (collectionView: CollectionView) => {
      const { collection_id, view_id, state, platform, language } = collectionView

      // Set all collectionViews to is_default: false, except the selected one to is_default: true
      const updatedCollectionViews = collectionViews.reduce<CollectionView[]>((acc, view) => {
        if (view.collection_id === collection_id && view.state === state && view.platform === platform) {
          if (view.language !== language) {
            acc.push({ ...view, is_default: false })
          } else if (view.view_id === view_id && view.language === language) {
            acc.push({ ...view, is_default: true })
          }
        }

        return acc
      }, [])

      // Dispatch an action for each updated collection view

      updatedCollectionViews.forEach((view) => {
        if (view)
          dispatch(
            updateCollectionView({
              collectionId: view.collection_id,
              viewId: view.view_id,
              state: view.state,
              platform: view.platform,
              language: view.language,
              isDefault: view.is_default,
              projectId: project.id,
            }),
          )
      })
    }

    return {
      cells: [
        {
          content: collectionView.view.name,
          key: collectionView.view.id,
        },
        {
          content: capitalizeFirstLetter(collectionView.state),
        },
        {
          content: scanPlatformLabels[collectionView.platform],
        },
        {
          content: resultScreenLanguageLabels[collectionView.language],
        },
        {
          content: (
            <RadioGroup
              value={collectionView.is_default ? 'yes' : 'no'}
              isDisabled={false}
              options={defaultViewRadioOptions}
              onChange={() => {
                onDefaultViewChange(collectionView)
              }}
              aria-labelledby="radiogroup-label"
              name={`${collectionView.state}_radioGroup`}
            />
          ),
        },
        {
          content: dateFormatter(collectionView.created_at),
          key: collectionView.created_at,
        },
        {
          content: (
            <Restricted to="collection-view:create" type="projects">
              <Button
                iconBefore={<TrashIcon label="" size="small" />}
                onClick={() => {
                  onSubmitDeleteCollectionView(collectionView)
                }}
              >
                Remove
              </Button>
            </Restricted>
          ),
        },
      ],
    }
  })

  const actions = (
    <Restricted to="collection-view:create" type="projects">
      <NewCollectionViewModal
        collectionViews={collectionViews}
        onSubmit={onSubmitNewCollectionView}
        // isFetching={isFetching}
        views={views}
        project={project}
        isOpen={isNewCollectionViewModalOpen}
        setIsOpen={setIsNewCollectionViewModalOpen}
      />
    </Restricted>
  )

  return (
    <>
      <PageHeader actions={actions}>Result views</PageHeader>

      <DynamicTable
        head={collectionViewsHead}
        rows={collectionViewsCells}
        rowsPerPage={15}
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={isFetching}
        isFixedSize
        defaultSortKey="created_at"
        defaultSortOrder="DESC"
        emptyView={<h2>No result views have been set up for this collection.</h2>}
      />
    </>
  )
}

export default connect(mapStateToProps)(ResultScreens)
